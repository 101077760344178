import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import {createStore} from 'redux'
import {Provider} from 'react-redux'
import reducer from './reducers'
import middleware from './middleware'
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// import * as Sentry from '@sentry/browser';

const state = window.__REDUX_STATE__ || {};
const store = createStore(reducer, state, middleware);
const release = window.__RELEASE__ || '';

// Sentry.init({
//   dsn: "https://e7735552e70748c3ac99b66ca976ddbd@sentry.io/2440412",
//   release: 'tour-ssr-react@' + release
// });

const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;

renderMethod(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
  , document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
