import React, {useState, useEffect, Fragment} from 'react';
import './warning.scss';

const Warning = (props) => {
    const [open, setOpen] = useState(true);
    const [mounted, setMounted] = useState(false);
    const {tour, resources } = props;

    const site = tour.site[0].toUpperCase() + tour.site.slice(1);
    let termsLink = '';
    let logo = '';
    let logo_height = '';
    let logo_width = '';
    try {
        const terms = tour.common.footer.links.filter((i) => i.text === 'Terms')
        termsLink = terms[0]['url'];
    } catch (e) {}
    try {
        const { filename, resolutions } = resources[tour.common.header.logo.id];
        logo = `${tour.config.staticMappings.logo}/mobile/logo/${filename}`;
        logo_height = '50px'; //resolutions['mobile']['height'];
        logo_width = '100%'; //resolutions['mobile']['width'];
    } catch (e) {}

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let params = (new URL(document.location)).searchParams;
            const warning =  params.get("warning") || true;
            const wasWarned = localStorage.getItem("wasWarned") || false;
            const hasURLParams = document.location.href.indexOf('?') !== -1;
            if (wasWarned || hasURLParams
                // params.get("aid") ||
                // params.get("cmp") ||
                // params.get("aff") ||
                // params.get("nats") ||
                // params.get("noWarning") ||
            ) {
                if(warning !== 'true'){
                    setOpen(false);
                    localStorage.setItem("wasWarned", true);
                }
            } else {
                document.body.classList.add("stop-scrolling");
            }
        }
        setTimeout(() => setMounted(true), 50);
    }, []);

    const handleEnterSite = () => {
        if (typeof window !== 'undefined') {
            localStorage.setItem("wasWarned", true);
            document.body.classList.remove("stop-scrolling");
        }
        setOpen(false);
    }

    return(
        <Fragment>
            { open &&
                <Fragment>
                    {mounted && <div className="warning-message">
                        <div className="warning-logo">{logo && <img alt={`Welcome to ${site}`} height={logo_height} src={logo}/>}</div>
                        <div className="warning-title"><div className="title">The official website of {site}! </div><div className="conditions">Must be 21 or older to enter.</div></div>
                        <div className="warning-text">
                            <p>This website contains information, links, images and videos of sexually explicit material. If you are
                                under the age of 21, if such material offends you or if it's illegal to view such material in your
                                community please do not continue. Here is an excellent website to find something more to your
                                tastes.Please read and comply with the following conditions before you continue:I am at least 21
                                years of age.The sexually explicit material I am viewing is for my own personal use and I will not
                                expose minors to the material. </p>

                            <p>I desire to receive/view sexually explicit material. </p>

                            <p>I believe that as an adult it is my inalienable right to receive/view sexually explicit
                                material. </p>

                            <p>I believe that sexual acts between consenting adults are neither offensive nor obscene. The viewing,
                                reading and downloading of sexually explicit materials does not violate the standards of my
                                community, town, city, state or country. </p>

                            <p>I am solely responsible for any false disclosures or legal ramifications of viewing, reading or
                                downloading any material in this site. Furthermore this website nor its affiliates will be held
                                responsible for any legal ramifications arising from fraudulent entry into or use of this
                                website. </p>

                            <p>I agree that by entering this website, I am subjecting myself and any business entity of which I have
                                any legal or equitable interest to the personal jurisdiction of the State of Florida should any
                                dispute arise at any time between this website and myself and/or such business entity. </p>

                            <p>This warning page constitutes a legal agreement between this website and you and/or any business in
                                which you have any legal or equitable interest. If any portion of this agreement is deemed
                                unenforceable by a court of competent jurisdiction it shall not affect the enforceability of the
                                other portions of the agreement. </p>

                            <p>All performers on this site are over the age of 18, have consented being photographed and/or filmed,
                                have signed model release and provided proof of age, believe it is their right to engage in
                                consensual sexual acts for the entertainment and education of other adults and believe it is your
                                right as an adult to watch them doing what adults do. </p>

                            <p>The videos and images in this site are intended to be used by responsible adults as sexual aids, to
                                provide sexual education and to provide sexual entertainment. </p>

                            <p>If you've read and fully understand the above agreement, and you affirm and swear that
                                viewing/downloading/receiving sexually explicit materials does not violate the standards of your
                                community, that you won't make any of the materials available to minors in any form, that you
                                believe it is your constitutional right to view these materials, that you are wholly liable for any
                                legal ramifications that may arise for your receiving or viewing of these materials and that you are
                                over the age of 21.</p>
                        </div>
                        {termsLink && <div className="warning-agree">
                            I agree to the <a href={termsLink} target="_blank" rel="noopener noreferrer" className="warning-link">terms and conditions.</a>
                        </div>}
                        <div className="warning-enter" onClick={handleEnterSite} onTouchEnd={handleEnterSite}>Click Here to Enter!</div>
                    </div>}
                    <div className="warning-container">{props.children}</div>
                    <div className="mask"></div>
                </Fragment>
            }
            {!open && props.children}
        </Fragment>
    );
}

export { Warning };
