import {applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

let middleware = applyMiddleware(thunk);

if (typeof window !== 'undefined') {
  let composeEnhancers = compose;
  if (process.env.NODE_ENV !== 'production') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  middleware = composeEnhancers(applyMiddleware(thunk));
}

export default middleware;
