import { receiveTour } from '../actions/tour';
import { getTour } from "../utils/data";

export function handleGetTour(hostname, id) {
    return (dispatch) => {
        return getTour(hostname, id)
            .then((tour) => {
                dispatch(receiveTour(tour))
            })
    }
}

