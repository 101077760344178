import React, {Component} from 'react';
import {connect} from 'react-redux';
import {handleGetTour} from '../actions/shared'
import {withRouter} from "react-router-dom";
import Tour from '../components/Tour'

class App extends Component {

  componentDidMount() {
    let state = window.__REDUX_STATE__ || {};
    // Only in case if the initial state it's empty (Non SSR)
    if (!Object.keys(state).length) {
      let urlParams = new URLSearchParams(window.location.search);
      const tourId = urlParams.has('id') ? urlParams.get('id') : 'v4-default';
      this.props.dispatch(handleGetTour(window.location.hostname, tourId));
    }
  }

  render() {
    return (
      <div className="app">
        {
          this.props.tour && (<Tour {...this.props}/>)
        }
      </div>
    );
  }
}

function mapStateToProps({tour, device, tracking, location, resources}) {
  return {
    tour,
    device,
    tracking,
    location,
    resources
  }
}

export default withRouter(connect(mapStateToProps)(App));
