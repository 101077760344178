import React, { Component } from 'react';
import { TourV7 } from '@logangroup/tours';
import { Warning } from './Warning/Warning';

class Tour extends Component {
  static registeredRoutes = ['join', '/', 'ab'];

  render() {
    const { tour } = this.props.tour;
    // This is only for browser use
    if (typeof window !== 'undefined') {
      let route = findRoutePath(window.location.pathname);
      if (!Tour.registeredRoutes.includes(route)) {
        let searchUrl = window.location.search;
        
        // Special case for old affiliate routes
        if (window.location.pathname.includes('/pps=')) {
          const specialCase =
            window.location.pathname
              .split('/')
              .find((s) => s.includes('pps=')) || '';
          let specialCaseParams = specialCase.split('=') || [];
          if (specialCaseParams.length === 2) {
            const specialCaseParams2 = specialCaseParams[1].split(',') || [];
            searchUrl += `${window.location.search === '' ? '?' : '&'}prg=${
              specialCaseParams[0]
            }`;

            if (specialCaseParams2.length === 2) {
              searchUrl += `&aid=${specialCaseParams2[0]}&cmp=${
                specialCaseParams2[1]
              }`;
            } else {
              searchUrl += `&aid=${specialCaseParams[1]}`;
            }
          }
          
        }
        // Append the noWarning parameter to all 404s
        searchUrl += `${searchUrl.includes('?') ? '&' : '?'}warning=false`
        window.location.href = window.location.origin + '/' + searchUrl;
      }
    }

    return <Warning {...this.props}><TourV7 {...this.props} /></Warning>;
  }
}

function findRoutePath(str) {
  const regex = /^\/([^?\/]+)/;
  return str.match(regex) ? str.match(regex)[1] : '/';
}

export default Tour;
